import OptionEditorMobile from "./OptionEditorMobile.jsx";
import UndoRedoMobile from "./UndoRedoMobile.jsx";
import ShowMenuButtonMobile from "./ShowMenuButtonMobile.jsx";
import KanbanEditorLeftMenuMobile from "./KanbanEditorLeftMenuMobile.jsx";
import KanbanEditorTopBarMobile from "./KanbanEditorTopBarMobile.jsx";
import ZoomSlider from "./ZoomSlider.jsx";
import KanbanEditorTopBar from "./KanbanEditorTopBar.jsx";

const KanbanEditorTopAllPhone = ({isMobileBrowser, kanEdit, hideMenu, selectedIndex, kanbanId, isKanbanEditor, scale, setScale}) =>{
    return(
        <div className={"w-full h-[60px] fixed left-0 top-0 z-50 flex justify-between"}>
            <div>
                {hideMenu && isMobileBrowser && <ShowMenuButtonMobile/>}

                {!hideMenu && isMobileBrowser && <KanbanEditorLeftMenuMobile selectedIndex={selectedIndex} kanbanId={kanbanId}/>}
            </div>

            <div className={"flex gap-2 w-72 h-fit absolute right-0 top-2  justify-end"}>
                {isMobileBrowser && isKanbanEditor && <ZoomSlider scale={scale} setScale={setScale}/>}
                {isMobileBrowser && kanEdit && <UndoRedoMobile  />}
            </div>

            {!hideMenu && isMobileBrowser && isKanbanEditor && <KanbanEditorTopBarMobile />}


        </div>
    )
}

export default KanbanEditorTopAllPhone