import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faUndo } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { setUndoAndRedoCount } from '../../../../redux/slices/kanbanSlice.js'

const KanbanEditorTopBar = ({ size, kanEdit }) => {
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const undoIcon = <FontAwesomeIcon icon={faUndo} color={yjsData.undoCount > 0 ? 'white' : 'grey'}/>
  const redoIcon = <FontAwesomeIcon icon={faRedo} color={yjsData.redoCount > 0 ? 'white' : 'grey'}/>
  const [name, setName] = useState('')
  const dispatch = useDispatch()





  useEffect(() => {
    const setUndoAndRedo = () => {
      dispatch(setUndoAndRedoCount({
        undoCount:yjsData.undoManager.undoStack.length,
        redoCount:yjsData.undoManager.redoStack.length,
      }))
    }
    yjsData.undoManager.on('stack-item-added', () => {
      setUndoAndRedo()
    })
    yjsData.undoManager.on('stack-item-popped', () => {
      setUndoAndRedo()
    })
    setUndoAndRedo()

  }, [])

  useEffect(() => {
    yjsData.kanbanPropsY.observe(e => setName(yjsData.kanbanPropsY.get('name')))
    setName(yjsData.kanbanPropsY.get('name'))
  }, [])



  return (
    <div style={{ height: size + 'px' }}
         className={'w-full bg-color5 p-2 pl-24 font-bold  items-center justify-between flex '}>
      <a className={'text-white text-2xl w-36 ellipsis'}>{name}</a>

      {kanEdit && <div className={'flex flex-row gap-2'}>
        <button style={{cursor: yjsData.undoManager.undoStack.length > 0 ? "pointer" : "auto"}}
                className={'cursor-auto'} onClick={() => {
          yjsData.undoManager.undo()
        }}>{undoIcon}</button>
        <button style={{cursor: yjsData.undoManager.redoStack.length > 0 ? "pointer" : "auto"}}
                className={'cursor-auto'} onClick={() => {
          yjsData.undoManager.redo()
        }}>{redoIcon}</button>
      </div>}
    </div>
  )
}

export default KanbanEditorTopBar